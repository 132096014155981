import {
  withYMaps,
  Map,
  ObjectManager,
  Polygon,
  RouteButton,
  useYMaps,
  YMaps,
  ZoomControl,
  Placemark,
} from "@pbe/react-yandex-maps";
import { createRef } from "react";
import "./App.css";
import React from "react";

import filials from "./coords.json";

function App() {
  const map = createRef(null);

  const [currentFilial, setCurrentFilial] = React.useState(filials[0]);

  const [polygons, setPolygons] = React.useState([
    {
      geometry: [
        [52.27, 76.96],
        [52.279, 76.962],
        [52.273, 76.965],
      ],
    },
    {
      geometry: [
        [49.207795, 73.104646],
        [49.407795, 73.404646],
        [50.807795, 72.804646],
      ],
    },
  ]);

  const onPolyClickHandler = (e) => {
    e.originalEvent.target.editor.startDrawing();
    console.log(e.originalEvent.target.geometry._coordPath._coordinates);
  };

  const changeMapZones = (e) => {
    setCurrentFilial(filials[e.target.value]);
  };

  return (
    <div className="App vh-100 d-flex flex-column">
      <header>
        <nav className=" ">
          <div className="navbar container navbar-expand-md align-items-center">
            <h2 className="navbar-brand fw-bold">Налив / Зоны доставки</h2>
            <div className="navbar-collpase">
              <ul className="navbar-nav ml-auto gap-2 h-100">
                {filials.map((filial, i) => (
                  <li className="nav-item ">
                    <button
                      className={`btn btn-sm h-100 fw-bold${
                        currentFilial.name === filial.name
                          ? " btn-dark"
                          : " btn-outline-dark"
                      }`}
                      onClick={changeMapZones}
                      value={i}
                    >
                      {filial.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="d-flex justify-content-center align-items-center h-full">
        <YMaps
          query={{
            apikey: "55160528-17fa-48ba-92f6-3d28dca083a7",
            load: "package.full",
          }}
        >
          <Map
            instanceRef={map}
            height={"90vh"}
            width={"95vw"}
            // defaultState={{ center: [52.2788, 76.965], zoom: 13 }}
            state={{ center: currentFilial.coordinates, zoom: 13 }}
          >
            <Placemark
              properties={{ iconCaption: currentFilial.name }}
              options={{ preset: "islands#circleIcon" }}
              geometry={currentFilial.coordinates}
            />

            {currentFilial.zones.map((zone) => (
              <Polygon
                geometry={zone.geometry}
                // onClick={onPolyClickHandler}
                properties={{
                  hintContent: zone.content + " тенге",
                  balloonContent: zone.content + " тенге",
                }}
                options={{
                  fillColor: zone.color,
                  strokeColor: "#000000",
                  opacity: 0.3,
                  strokeWidth: 4,
                  strokeStyle: "shortdash",
                }}
              />
            ))}
            {/* {polygons.map((polygon, id) => (
              <Polygon
                key={id}
                onClick={onPolyClickHandler}
                geometry={[polygon.geometry]}
                options={{
                  fillColor: "#00FF00",
                  strokeColor: "#0000FF",
                  opacity: 0.5,
                  strokeWidth: 5,
                  strokeStyle: "shortdash",
                }}
              />
            ))} */}
            <RouteButton />
            <ZoomControl />
          </Map>
        </YMaps>
      </main>
    </div>
  );
}

export default App;
